import { ArrowRight } from "@xxl/icons";
import {
  getButtonVariant,
  getUniqueProps,
  type CampaignCarouselProps,
} from "./CampaignCarousel.helper";
import {
  Description,
  HeaderContainer,
  Link,
  TextContainer,
  Title,
} from "./CampaignCarousel.styled";
import { SiteContainer } from "../common/SiteContainer/SiteContainer";
import { siteContainerMediaQuery } from "@/react-app/utils/xxl-screen";
import { NextImage } from "../common/NextImage/NextImage";
import { useXxlMediaQuery } from "@/react-app/hooks/useXxlMediaQuery";
import ConditionalLink from "@/react-app/components/ConditionalLink/ConditionalLink";
import { containerMaxWidth } from "@/react-app/styles/xxl-theme";
import { XxlButton } from "@/react-components/Common/XxlButton";

const CampaignCarousel = (props: CampaignCarouselProps) => {
  const {
    backgroundColor,
    image,
    Slider,
    title,
    description,
    TopComponent,
    headerType,
  } = props;
  const isLaptop = useXxlMediaQuery("LaptopMediaQuery");
  const { button, link } = getUniqueProps(props);
  const headerHeight = isLaptop ? 210 : 200;

  return (
    <>
      <SiteContainer hideBottomPadding={true} style={{ position: "relative" }}>
        <ConditionalLink
          condition={headerType === "HEADER_AS_LINK"}
          url={link?.href ?? ""}
        >
          <div style={{ height: `${headerHeight}px` }}>
            {image !== undefined && (
              <NextImage
                src={image.desktopSrc}
                alt={image.alt}
                hotspot={image.hotspot}
                height={headerHeight}
                width={isLaptop ? 1440 : 375}
                sizes={`${siteContainerMediaQuery} ${containerMaxWidth}px, 97vw`}
                style={{
                  objectFit: "cover",
                  width: "100%",
                  maxWidth: "1332px",
                }}
              />
            )}

            <HeaderContainer
              backgroundColor={
                image === undefined ? backgroundColor : "transparent"
              }
            >
              <TextContainer>
                {TopComponent !== undefined && TopComponent}
                <Title style={{ color: title.color }}>{title.text}</Title>
                {description !== undefined && (
                  <Description style={{ color: description.color }}>
                    {description.text}
                  </Description>
                )}
              </TextContainer>
              {button !== null && (
                <>
                  {isLaptop ? (
                    <XxlButton
                      style={{ width: "fit-content" }}
                      href={button.href}
                      variant={getButtonVariant(
                        isLaptop,
                        button.backgroundColor
                      )}
                    >
                      <span>{button.text}</span>
                      <ArrowRight
                        fontSize={20}
                        style={{ position: "relative", top: "1px" }}
                      />
                    </XxlButton>
                  ) : (
                    <Link
                      href={button.href}
                      style={{ color: button.backgroundColor }}
                    >
                      {button.text}
                      <ArrowRight
                        fontSize={16}
                        style={{
                          position: "relative",
                          top: "3px",
                          marginLeft: "6px",
                        }}
                      />
                    </Link>
                  )}
                </>
              )}
            </HeaderContainer>
          </div>
        </ConditionalLink>
      </SiteContainer>
      {Slider}
    </>
  );
};

export { CampaignCarousel };
