import React from "react";
import { BannerContent } from "../Shared/BannerContent";
import type {
  FullGridBanner as FullGridBannerType,
  Image,
} from "@xxl/content-api";
import { useXxlMediaQuery } from "../../../hooks/useXxlMediaQuery";
import { Slider } from "../../Common/Slider";
import { isNotNullOrUndefined } from "@xxl/common-utils";
import { withErrorBoundary } from "../../../utils/WithErrorBoundary/with-error-boundary";
import type { ImageData } from "@xxl/frontend-api";
import type { ImageSettings } from "../Shared/BannerContent/BannerContent.helper";
import {
  componentIndicesOnHomepageWithHighPrio,
  getBannerHeights,
  toBannerContentProps,
} from "../Shared/BannerContent/BannerContent.helper";

/**
 * Todo XD-13282: remove FullGridBannerFixed after Next.js Homepage release.
 * it's only needed because spring boot transforms the object from content-api.
 */
export type FullGridBannerFixed = Omit<
  FullGridBannerType,
  "image" | "mobileImage"
> & {
  image: Image | ImageData;
  mobileImage?: Image | ImageData;
};

export type FullGridBannerProps = {
  content: FullGridBannerFixed[] | null;
  positionInHomepage: number;
};

const _FullGridBanner = ({
  content,
  positionInHomepage,
}: FullGridBannerProps) => {
  const isMobile = useXxlMediaQuery("MobileMediaQuery");
  const isLaptop = useXxlMediaQuery("LaptopMediaQuery");

  if (content === null) {
    return null;
  }

  const banners = content.map((banner, arrayIndex) => {
    const height = getBannerHeights().fullGrid;

    const sizes = `
        (max-width: 400px) 400w,
        (max-width: 600px) 600w,
        (max-width: 800px) 800w,
        (max-width: 1000px) 1000w,
        1332w
      `;

    const fetchPriority =
      componentIndicesOnHomepageWithHighPrio.includes(positionInHomepage) &&
      arrayIndex === 0
        ? "high"
        : "low";

    const imageSettings: ImageSettings = {
      fetchPriority,
      isMobile,
      sizes,
      srcSetSizes: [
        { width: 400, size: "400w" },
        { width: 600, size: "600w" },
        { width: 800, size: "800w" },
        { width: 1000, size: "1000w" },
        { width: 1332, size: "1332w" },
        { width: 2664, size: "2664w" },
      ],
    };

    const bannerProps = toBannerContentProps({
      banner,
      height,
      variant: "full",
      imageSettings,
      isLaptop,
      linkVariant: "WITH_LINK_AND_BUTTONS",
    });

    return <BannerContent {...bannerProps} key={arrayIndex} />;
  });

  if (banners.length === 1) {
    return banners[0];
  }

  return (
    <Slider
      items={banners.filter(isNotNullOrUndefined)}
      loop={{
        shouldLoop: true,
        autoLoopTimeInMs: 5000,
      }}
      navigation={isLaptop ? "ARROWS" : "DOTS"}
      slidesConfig={{ perView: 1, spacing: 0 }}
      navigationMargin={{
        left: 0,
        right: 0,
        vertical: 0,
      }}
    />
  );
};

export const FullGridBanner = withErrorBoundary(_FullGridBanner);
