import type { VideoData } from "@xxl/frontend-api";
import React from "react";
import { useTranslations } from "../../../contexts/Translations/TranslationsContext";
import { StyledWrapper, StyledVideo, StyledIFrame } from "./BannerVideo.styled";

type BannerProps = VideoData & {
  isContentProductCarousel?: boolean;
};

export const BannerVideo = ({
  url,
  origin,
  isContentProductCarousel = false,
}: BannerProps): JSX.Element => {
  const { t } = useTranslations();
  return (
    <StyledWrapper
      className={
        isContentProductCarousel
          ? "content-product-carousel__video"
          : "category-promoted-service__banner"
      }
      as={isContentProductCarousel ? "div" : "li"}
      isContentProductCarousel={isContentProductCarousel}
    >
      {origin === "Youtube" ? (
        <StyledIFrame
          src={url}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen={true}
          title={`${origin} video`}
        />
      ) : origin === "Vimeo" ? (
        <StyledIFrame
          src={url}
          frameBorder="0"
          allow="autoplay; fullscreen"
          allowFullScreen={true}
          title={`${origin} video`}
        />
      ) : (
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <StyledVideo controls={false}>
          <source src={url} type="video/mp4" />
          {t("browser.unsupported.html5video")}
        </StyledVideo>
      )}
    </StyledWrapper>
  );
};
