import React from "react";
import type { HalfGridBanner as HalfGridBannerType } from "@xxl/content-api";
import { Container } from "./HalfGridBanner.styled";
import { BannerContent } from "../Shared/BannerContent";
import { useXxlMediaQuery } from "../../../hooks/useXxlMediaQuery";
import { withErrorBoundary } from "../../../utils/WithErrorBoundary/with-error-boundary";
import type { ImageSettings } from "../Shared/BannerContent/BannerContent.helper";
import {
  componentIndicesOnHomepageWithHighPrio,
  getBannerHeights,
  toBannerContentProps,
} from "../Shared/BannerContent/BannerContent.helper";

type HalfGridBannerProps = {
  firstBanner: HalfGridBannerType;
  secondBanner: HalfGridBannerType;
  positionInHomepage: number;
};

const _HalfGridBanner = ({
  firstBanner,
  secondBanner,
  positionInHomepage,
}: HalfGridBannerProps) => {
  const isMobile = useXxlMediaQuery("MobileMediaQuery");
  const height = getBannerHeights().halfGrid;
  const sizes = `
    (max-width: 400px) 400w,
    (max-width: 600px) 600w,
    (max-width: 800px) 800w,
    (max-width: 1000px) 1000w,
    (max-width: 1279px) 1279w,
    660w
  `;
  const srcSetSizes = [
    { width: 400, size: "400w" },
    { width: 600, size: "600w" },
    { width: 800, size: "800w" },
    { width: 1000, size: "1000w" },
    { width: 1279, size: "1279w" },
    { width: 660, size: "660w" },
  ];
  const imageSettings: ImageSettings | undefined = {
    fetchPriority: componentIndicesOnHomepageWithHighPrio.includes(
      positionInHomepage
    )
      ? "high"
      : "low",
    isMobile,
    sizes,
    srcSetSizes,
  };
  const variant = "half";
  const firstBannerProps = toBannerContentProps({
    banner: firstBanner,
    height,
    variant,
    imageSettings,
    isLaptop: !isMobile,
    linkVariant: "WITH_LINK_AND_BUTTONS",
  });
  const secondBannerProps = toBannerContentProps({
    banner: secondBanner,
    height,
    variant,
    imageSettings,
    isLaptop: !isMobile,
    linkVariant: "WITH_LINK_AND_BUTTONS",
  });

  return (
    <Container>
      <BannerContent {...firstBannerProps} />
      <BannerContent {...secondBannerProps} />
    </Container>
  );
};

export const HalfGridBanner = withErrorBoundary(_HalfGridBanner);
export type { HalfGridBannerProps };
