import { ArrowRight } from "@xxl/icons";
import React from "react";
import { useXxlMediaQuery } from "../../../../hooks/useXxlMediaQuery";
import ConditionalLink from "../../../ConditionalLink/ConditionalLink";
import { XxlButton } from "../../../Common/XxlButton";
import {
  getButtonVariant,
  getUniqueProps,
  type BannerContentProps,
  formatBannerText,
} from "./BannerContent.helper";
import {
  ButtonContainer,
  SecondaryContainer,
  Description,
  Main,
  Tagline,
  TextContainer,
  Timer,
  Title,
  MainContainer,
  HeroTitle,
} from "./BannerContent.styled";
import { ImageWithFetchPrio } from "../../../Common/Image/ImageWithFetchPrio";
import { withErrorBoundary } from "../../../../utils/WithErrorBoundary/with-error-boundary";
import { isEmpty, isNotEmpty, isNotNullOrUndefined } from "@xxl/common-utils";
import { color } from "@xxl/theme";

const MAX_NR_OF_BUTTONS_MOBILE = 3;

const _BannerContent = (props: BannerContentProps) => {
  const isLaptop = useXxlMediaQuery("LaptopMediaQuery");
  const {
    alignment,
    backgroundImage,
    backgroundColor,
    countdownTimer,
    description,
    hasMobileButtonBar,
    height,
    padding,
    style,
    tagline,
    title,
    linkVariant,
    testid,
    variant,
    isScalable = false,
  } = props;
  const { links, url } = getUniqueProps(props);
  const shouldShowTagline = !isEmpty((tagline?.text ?? "").trim());
  const hasMainArea =
    description !== undefined ||
    shouldShowTagline ||
    title !== undefined ||
    countdownTimer !== undefined;
  const hasLinks = links !== null;
  const hasOnlyOneLink = hasLinks && links.length === 1;
  const hasLinkAndButtons = linkVariant === "WITH_LINK_AND_BUTTONS";
  const oneLinkUrl = hasOnlyOneLink ? links[0].url : null;
  const isNestedLink = hasLinks && (isNotEmpty(oneLinkUrl) || isNotEmpty(url));

  const wrapperStyle = {
    ...{ width: "100%" },
    ...style,
  };

  const hasGhostLinks =
    variant !== "full" &&
    variant !== "half" &&
    (linkVariant === "WITH_GHOST_LINK_BUTTONS" ||
      linkVariant === "WITH_LINK_AND_BUTTONS");

  const resetGhostLinkOnMobile =
    hasGhostLinks && !isLaptop && !hasMobileButtonBar;

  const reduceGhostLinkLeftPadding =
    (hasGhostLinks && alignment === "LEFT" && isLaptop) ||
    resetGhostLinkOnMobile;

  const flexAlignment =
    alignment === "CENTER" && !resetGhostLinkOnMobile ? "center" : "flex-start";

  const BannerTitle = title?.type === "h1" ? HeroTitle : Title;

  const handleNestedClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    href: string
  ) => {
    e.stopPropagation();
    e.preventDefault();

    if (typeof window !== "undefined" && isNotEmpty(href)) {
      window.open(href, e.ctrlKey || e.metaKey ? "_blank" : "_self");
    }
  };

  return (
    <ConditionalLink
      url={url ?? oneLinkUrl ?? ""}
      condition={
        linkVariant === "WITH_LINK" || !isEmpty(url) || !isEmpty(oneLinkUrl)
      }
      style={wrapperStyle}
    >
      <MainContainer
        data-testid={testid}
        containerHeight={{
          ...height,
          ...(isScalable
            ? {
                smallTablet: "100%",
                desktop: "100%",
              }
            : {}),
        }}
        backgroundColor={backgroundColor}
        style={
          linkVariant === "WITH_LINK_BUTTONS" ||
          linkVariant === "WITH_GHOST_LINK_BUTTONS" ||
          linkVariant === "WITH_LINK_AND_BUTTONS"
            ? wrapperStyle
            : {}
        }
      >
        {/* Todo XD-13282: use NextImage when Next.js homepage is released. */}
        {backgroundImage !== undefined && (
          <ImageWithFetchPrio
            sizes={backgroundImage.sizes}
            src={`${backgroundImage.url}`}
            srcSet={backgroundImage.srcSet}
            alt={backgroundImage.alt}
            fetchPriority={backgroundImage.fetchPriority}
            style={{ objectFit: "cover", width: "100%", height: "100%" }}
          />
        )}
        <SecondaryContainer
          hasOnlyOneLink={
            hasOnlyOneLink && linkVariant !== "WITH_LINK_AND_BUTTONS"
          }
        >
          <TextContainer padding={padding.textContainer}>
            {isNotNullOrUndefined(countdownTimer) &&
              flexAlignment === "flex-start" && (
                <Timer alignment={flexAlignment}>{countdownTimer}</Timer>
              )}
            {hasMainArea && (
              <Main alignment={flexAlignment}>
                {isNotNullOrUndefined(countdownTimer) &&
                  flexAlignment === "center" && (
                    <Timer alignment={flexAlignment}>{countdownTimer}</Timer>
                  )}
                {shouldShowTagline && tagline !== undefined && (
                  <Tagline isScalable={isScalable} color={tagline.color}>
                    {tagline.text}
                  </Tagline>
                )}
                {title !== undefined && (
                  <BannerTitle
                    isScalable={isScalable}
                    color={title.color}
                    size={title.size}
                  >
                    {formatBannerText(title.text)}
                  </BannerTitle>
                )}
                {description !== undefined && (
                  <Description
                    color={description.color}
                    size={description.size}
                    isScalable={isScalable}
                  >
                    {formatBannerText(description.text)}
                  </Description>
                )}
              </Main>
            )}
          </TextContainer>
          {hasLinks && (
            <ButtonContainer
              as={hasOnlyOneLink ? "span" : undefined}
              alignment={{
                mobile: hasMobileButtonBar ? "center" : flexAlignment,
                largeScreen: flexAlignment,
              }}
              backgroundColor={
                hasMobileButtonBar ? color.webBlack.hex : "transparent"
              }
              padding={padding.buttonContainer}
            >
              {links.map(
                (
                  { backgroundColor: buttonBgColor, text, url: buttonUrl },
                  index
                ) => {
                  if (!isLaptop && index >= MAX_NR_OF_BUTTONS_MOBILE) {
                    return null;
                  }

                  return (
                    <XxlButton
                      variant={getButtonVariant(
                        isLaptop,
                        buttonBgColor,
                        linkVariant,
                        variant
                      )}
                      key={url ?? index}
                      size={"small"}
                      href={buttonUrl}
                      style={{
                        width: "auto",
                        textDecoration:
                          isLaptop || resetGhostLinkOnMobile
                            ? "none"
                            : "underline",
                        ...(reduceGhostLinkLeftPadding && { paddingLeft: 0 }),
                        ...(hasGhostLinks && {
                          height: "auto",
                          ...(hasOnlyOneLink || hasLinkAndButtons
                            ? { backgroundColor: "transparent" }
                            : undefined),
                        }),
                        letterSpacing: -0.12,
                      }}
                      isNestedLink={isNestedLink}
                      onClick={(e) =>
                        isNestedLink
                          ? handleNestedClick(e, buttonUrl)
                          : undefined
                      }
                      role={isNestedLink ? "button" : undefined}
                    >
                      <span>{text.text}</span>
                      {(isLaptop || resetGhostLinkOnMobile) && (
                        <ArrowRight
                          fontSize={20}
                          style={{ position: "relative", top: "1px" }}
                        />
                      )}
                    </XxlButton>
                  );
                }
              )}
            </ButtonContainer>
          )}
        </SecondaryContainer>
      </MainContainer>
    </ConditionalLink>
  );
};

export const BannerContent = withErrorBoundary(_BannerContent);
